.amfaq-search {
    max-width: 600px;
    position: relative;

    .am-input {
        padding-right: 55px;
    }

    .am-button {
        height: 40px;
        width: 40px;
        padding: 0;
        line-height: 40px;
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
        background: none;
        color: $brand__primary__color;

        &::after {
            @include icon-pseudo($icon-search);
        }
    }

    .search-autocomplete ul li {
        cursor: pointer;

        &:hover {
            color: $brand__primary__color;
        }
    }
}

.am-widget-categories-3 {
    @include make-row();

    padding: 3rem 0 0;

    .am-widget-category {
        @include make-col-ready();

        margin-bottom: 2rem;
        text-align: center;

        @include min-screen($screen__l) {
            @include make-col(4);
        }

        .am-category-header {
            font-size: 2rem;
            font-weight: $font-weight__bold;
            color: $brand__secondary__color;
            margin-bottom: 0.2em;
            text-align: center;
        }

        .view-all {
            @include lib-link-as-button();
            @include lib-button();

            border-radius: $brand__border-radius;

            &::after {
                @include icon-pseudo($icon-right);
            }
        }
    }
}

.amfaq_questions {
    margin-bottom: 1.5rem;
    text-align: left;

    .am-item {
        .am-title {
            @include brand-block(
                $padding: 1rem 3rem 1rem 1.5rem,
                $background: $brand__secondary__lighter__extra,
                $border: none
            );

            position: relative;
            margin-bottom: 1rem;
            border-radius: $brand__border-radius;
            font-weight: $font-weight__bold;

            &:hover {
                cursor: pointer;
            }

            &::after {
                @include icon-pseudo($icon-down);

                position: absolute;
                top: 50%;
                right: 1rem;
                width: 14px;
                height: 14px;
                text-align: center;
                line-height: 1;
                vertical-align: 0;
                transform: translateY(-50%);
                transform-origin: center;
                transition: transform 300ms ease;
            }

            &[aria-expanded="true"]::after {
                transform: rotate(180deg) translateY(50%);
            }
        }

        .am-content {
            padding: 0 1rem;
            margin-bottom: 2rem;
            display: none;

            .amfaq-question-footer-wrapper {
                padding-top: 0.5em;
            }
        }
    }
}

.block-faq-sidebar {
    @include brand-block(
        $padding: 2rem,
        $background: $brand__secondary__lighter__extra,
        $border: none
    );

    border-radius: $brand__border-radius;

    .block-title {
        font-weight: $font-weight__semibold;
        color: $text__color;
        font-size: 2rem;
        margin-bottom: 0.3em;
    }

    .amfaq_categories {
        padding-top: 1.5rem;

        .item.title {
            font-weight: $font-weight__bold;
            margin-bottom: 0.2em;
        }

        .item a {
            color: $brand__secondary__color;

            &::before {
                @include icon-pseudo($icon-right);
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
}
